import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const LegalIcon: FC<SVGIconProps> = ({
  className,
  width = 24,
  height = 24,
  ...rest
}) => (
  <svg
    className={classNames("fill-current", className)}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M4.70577 11.3662L12 7.15489L19.2942 11.3662L19.7942 10.5002C20.0704 10.0219 19.9065 9.41033 19.4282 9.13419L12.5084 5.13902C12.2705 5.0017 11.9997 4.97319 11.7536 5.03805C11.6592 5.05983 11.5664 5.09581 11.4781 5.14676L4.5718 9.13414C4.09351 9.41028 3.92963 10.0219 4.20577 10.5002L4.70577 11.3662Z" />
    <path d="M4 18.0002C3.44772 18.0002 3 18.4479 3 19.0002V20.0002H21V19.0002C21 18.4479 20.5523 18.0002 20 18.0002H4Z" />
    <path d="M8 12.0002H6V17.0002H8V12.0002Z" />
    <path d="M11 12.0002H13V17.0002H11V12.0002Z" />
    <path d="M18 12.0002H16V17.0002H18V12.0002Z" />
  </svg>
);

export default LegalIcon;
