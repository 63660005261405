import ArrowReturnIcon from "@components/Icons/ArrowReturnIcon";
import BlockIcon from "@components/Icons/BlockIcon";
import BroadcastingIcon from "@components/Icons/BroadcastingIcon";
import CalendarIcon from "@components/Icons/CalendarIcon";
import CheckIcon from "@components/Icons/CheckIcon";
import ClockIcon from "@components/Icons/ClockIcon";
import CreditCardIcon from "@components/Icons/CreditCardIcon";
import DocDraftIcon from "@components/Icons/DocDraftIcon";
import DocumentIcon from "@components/Icons/DocumentIcon";
import EmailIcon from "@components/Icons/EmailIcon";
import EmailSentIcon from "@components/Icons/EmailSentIcon";
import EmailUnreadIcon from "@components/Icons/EmailUnreadIcon";
import EventsIcon from "@components/Icons/EventsIcon";
import FailedIcon from "@components/Icons/FailedIcon";
import FolderIcon from "@components/Icons/FolderIcon";
import FormIcon from "@components/Icons/FormIcon";
import GroupApptIcon from "@components/Icons/GroupApptIcon";
import LegalIcon from "@components/Icons/LegalIcon";
import LoadingIcon from "@components/Icons/LoadingIcon";
import LockOutlineIcon from "@components/Icons/LockOutlineIcon";
import LoopIcon from "@components/Icons/LoopIcon";
import MailIcon from "@components/Icons/MailIcon";
import NoteIcon from "@components/Icons/NoteIcon";
import PauseIcon from "@components/Icons/PauseIcon";
import QuestionMarkIcon from "@components/Icons/QuestionMarkIcon";
import RightArrowIcon from "@components/Icons/RightArrowIcon";
import SharedIcon from "@components/Icons/SharedIcon";
import SocialWebIcon from "@components/Icons/SocialWebIcon";
import SparkleIcon from "@components/Icons/SparkleIcon";
import SystemIcon from "@components/Icons/SystemIcon";
import ViewOnIcon from "@components/Icons/ViewOnIcon";

import { ArtifactStatusUtilsType } from "./types";

// shared items styles
const cancelledItem = {
  icon: BlockIcon,
  bgColor: "bg-peach-800",
  iconColor: "text-peach-400",
  statusColor: "text-peach-400",
  statusLabel: "Cancelled",
};

const declinedItem = {
  ...cancelledItem,
  statusLabel: "Declined",
};

const failedItem = {
  icon: EmailUnreadIcon,
  bgColor: "bg-peach-500",
  iconColor: "text-peach-400",
  statusColor: "text-peach-400",
  statusLabel: "Failed",
};

const undreadItem = {
  icon: EmailUnreadIcon,
  bgColor: "bg-peach-500",
  iconColor: "text-white",
  statusColor: "text-peach-500",
  statusLabel: "Unread",
};

const incompleteItem = {
  icon: ClockIcon,
  bgColor: "bg-grey-900",
  iconColor: "text-grey-500",
  statusColor: "text-grey-500",
};

const invitedItem = {
  icon: SharedIcon,
  bgColor: "bg-blue-900",
  iconColor: "text-blue-300",
  statusColor: "text-blue-300",
  statusLabel: "Invited",
};

const scheduledItem = {
  icon: ClockIcon,
  bgColor: "bg-action-900",
  iconColor: "text-action-500",
  statusColor: "text-action-500",
};

const scheduledGreenItem = {
  icon: ClockIcon,
  bgColor: "bg-green-800",
  iconColor: "text-green-500",
  statusColor: "text-green-500",
};

const confirmedItem = {
  icon: CheckIcon,
  bgColor: "bg-green-600",
  iconColor: "text-green-200",
  statusColor: "text-green-200",
};

const sharedItem = {
  icon: SharedIcon,
  bgColor: "bg-blue-900",
  iconColor: "text-blue-300",
  statusColor: "text-blue-300",
  statusLabel: "Shared",
};

const broadcastedItem = {
  icon: BroadcastingIcon,
  bgColor: "bg-violet-900",
  iconColor: "text-violet-300",
  statusColor: "text-violet-300",
  statusLabel: "Broadcasted",
};

const viewedItem = {
  icon: ViewOnIcon,
  bgColor: "bg-action-800",
  iconColor: "text-action-500",
  statusColor: "text-action-500",
  statusLabel: "Viewed",
};

const privateItem = {
  icon: LockOutlineIcon,
  bgColor: "bg-black-ink",
  iconColor: "text-white",
  statusColor: "text-black-ink",
  statusLabel: "Private",
};

const systemItem = {
  icon: SystemIcon,
  bgColor: "bg-action-800",
  iconColor: "text-action-500",
  statusColor: "text-action-500",
  statusLabel: "System",
};

const refundItem = {
  icon: ArrowReturnIcon,
  bgColor: "bg-grey-950",
  iconColor: "text-black-ink",
  statusColor: "text-black-ink",
  statusLabel: "Refunded",
};

const processingACHItem = {
  ...confirmedItem,
  icon: LegalIcon,
  statusLabel: "Processing via ACH",
};

const sharedStatusItem = {
  noStatus: { ...incompleteItem, statusLabel: "No status" },
};

const LibraryStatusItem = {
  ...sharedStatusItem,
  shared: sharedItem,
  primary: privateItem,
  private: privateItem,
  broadcasted: broadcastedItem,
  system: systemItem,
  deleted: { ...cancelledItem, statusLabel: "Deleted" },
  scheduled: { ...incompleteItem, statusLabel: "Scheduled" },
};

const appointmentItem = {
  icon: CalendarIcon,
  statusIcons: {
    ...sharedStatusItem,
    confirmed: { ...confirmedItem, statusLabel: "Accepted" },
    pending: { ...invitedItem, statusLabel: "Invited" },
    maybe: {
      ...viewedItem,
      icon: QuestionMarkIcon,
      statusLabel: "Maybe",
    },
    cancelled: cancelledItem,
    declined: declinedItem,
    shared: sharedItem,
    needsAction: { ...invitedItem, statusLabel: "Invited" },
    accepted: { ...confirmedItem, statusLabel: "Accepted" },
    tentative: {
      ...viewedItem,
      icon: QuestionMarkIcon,
      statusLabel: "Maybe",
    },
    deleted: { ...cancelledItem, statusLabel: "Deleted" },
  },
};

export const artifactStatusUtils: ArtifactStatusUtilsType = {
  appointments: appointmentItem,
  groupAppointments: { ...appointmentItem, icon: GroupApptIcon },
  eventAttendees: {
    icon: EventsIcon,
    statusIcons: {
      ...sharedStatusItem,
      accepted: { ...confirmedItem, statusLabel: "Accepted" },
      tentative: { ...viewedItem, statusLabel: "Maybe" },
      declined: cancelledItem,
      needsAction: { ...invitedItem, statusLabel: "Invited" },
      shared: sharedItem,
    },
  },
  emailThreads: {
    icon: EmailIcon,
    statusIcons: {
      ...sharedStatusItem,
      read: {
        ...confirmedItem,
        statusLabel: "Read",
      },
      sent: {
        ...sharedItem,
        icon: EmailSentIcon,
        statusLabel: "Sent",
      },
      unread: undreadItem,
      draft: {
        ...incompleteItem,
        icon: DocDraftIcon,
        statusLabel: "Draft",
      },
    },
  },
  forms: {
    icon: FormIcon,
    statusIcons: {
      ...sharedStatusItem,
      draft: {
        ...incompleteItem,
        icon: DocDraftIcon,
        statusLabel: "Draft",
      },
      scheduled: { ...incompleteItem, statusLabel: "Scheduled" },
      pending: { ...incompleteItem, statusLabel: "Pending" },
      shared: sharedItem,
      viewed: viewedItem,
      submitted: { ...confirmedItem, statusLabel: "Submitted" },
      deleted: { ...cancelledItem, statusLabel: "Deleted" },
    },
  },
  payments: {
    icon: CreditCardIcon,
    statusIcons: {
      ...sharedStatusItem,
      draft: { ...incompleteItem, icon: DocDraftIcon, statusLabel: "Draft" },
      open: { ...incompleteItem, icon: DocDraftIcon, statusLabel: "Draft" },
      shared: sharedItem,
      viewed: viewedItem,
      void: cancelledItem,
      paid: { ...confirmedItem, statusLabel: "Paid" },
      failed: { ...cancelledItem, icon: FailedIcon, statusLabel: "Failed" },
      scheduled: { ...scheduledItem, statusLabel: "Scheduled" },
      processing: { ...scheduledItem, statusLabel: "Scheduled" },
      refunded: refundItem,
      partially_refunded: { ...refundItem, statusLabel: "Partially refunded" },
      "processing-ach": processingACHItem,
    },
  },
  invoices: {
    icon: CreditCardIcon,
    statusIcons: {
      ...sharedStatusItem,
      shared: sharedItem,
      scheduled: { ...scheduledGreenItem, statusLabel: "Scheduled" },
      failed: { ...cancelledItem, icon: FailedIcon, statusLabel: "Failed" },
      paid: { ...confirmedItem, statusLabel: "Paid" },
      "review-needed": {
        icon: SparkleIcon,
        bgColor: "bg-action-900",
        iconColor: "text-action-500",
        statusLabel: "Review needed",
      },
      rollover: {
        icon: RightArrowIcon,
        bgColor: "bg-blue-900",
        iconColor: "text-blue-500",
        statusLabel: "Rolled over",
      },
      "pre-approved": {
        icon: CheckIcon,
        iconColor: "text-grey-800",
        bgColor: "border border-dashed border-grey-800",
        statusLabel: "Pre-approved",
      },
      "approved-auto-charge": {
        icon: CreditCardIcon,
        iconColor: "text-green-200",
        bgColor: "bg-green-900",
        statusLabel: "Approved for auto charge",
      },
      "approved-manual": {
        icon: MailIcon,
        iconColor: "text-green-200",
        bgColor: "bg-green-900",
        statusLabel: "Approved for manual delivery",
      },
    },
  },
  subscriptions: {
    icon: LoopIcon,
    statusIcons: {
      canceled: {
        ...cancelledItem,
        bgColor: "bg-grey-950",
        iconColor: "text-grey-500",
        statusColor: "text-grey-500",
      },
      active: {
        statusLabel: "Active",
        icon: LoopIcon,
        bgColor: "bg-green-200",
        iconColor: "text-white",
        statusColor: "text-green-200",
      },
      paused: {
        statusLabel: "Paused",
        icon: PauseIcon,
        bgColor: "bg-grey-950",
        iconColor: "text-grey-500",
        statusColor: "text-grey-500",
      },
      processing: {
        statusLabel: "Processing",
        icon: LoadingIcon,
        bgColor: "bg-green-200",
        iconColor: "text-white",
        statusColor: "text-green-200",
      },
      scheduled: {
        ...scheduledItem,
        icon: LoopIcon,
        statusLabel: "Scheduled",
      },
      complete: {
        ...confirmedItem,
        statusLabel: "Completed",
      },
    },
  },
  "payment-item": {
    icon: CreditCardIcon,
    statusIcons: {
      ...sharedStatusItem,
      paid: { ...confirmedItem, statusLabel: "Paid" },
      failed: failedItem,
      scheduled: { ...scheduledGreenItem, statusLabel: "Scheduled" },
      shared: sharedItem,
      cancelled: cancelledItem,
      "processing-ach": processingACHItem,
    },
  },
  files: {
    icon: DocumentIcon,
    statusIcons: LibraryStatusItem,
  },
  links: {
    icon: SocialWebIcon,
    statusIcons: LibraryStatusItem,
  },
  folders: {
    icon: FolderIcon,
    statusIcons: LibraryStatusItem,
  },
  notes: {
    icon: NoteIcon,
    statusIcons: {
      ...sharedStatusItem,
      deleted: { ...cancelledItem, statusLabel: "Deleted" },
      shared: sharedItem,
      private: privateItem,
    },
  },
};
