import React from "react";
import classNames from "classnames";

interface InfoBoxDetailsPageProps {
  icon: React.ReactNode;
  iconClassName?: string;
  title?: string;
  children: React.ReactNode;
  withEllipsis?: boolean;
  titleClassName?: string;
  rightComponent?: React.ReactNode;
}

const InfoBoxDetailsPage: React.FC<InfoBoxDetailsPageProps> = ({
  icon,
  iconClassName,
  title,
  children,
  withEllipsis = false,
  titleClassName = "text-left",
  rightComponent,
}) => {
  return (
    <div className="flex items-center justify-between flex-wrap sm:flex-nowrap mb-4 last:mb-0 md:mb-0">
      {icon && (
        <div className="shrink-1 text-black-ink">
          <div
            className={classNames(
              iconClassName,
              "rounded-full w-10 h-10 flex items-center justify-center"
            )}
          >
            {icon}
          </div>
        </div>
      )}

      <div
        className={classNames(
          "flex-1 ml-4",
          withEllipsis && "text-ellipsis overflow-hidden"
        )}
      >
        {title && (
          <h1 className={classNames("text-sm text-grey-500", titleClassName)}>
            {title}
          </h1>
        )}
        {children}
      </div>
      {rightComponent}
    </div>
  );
};

export default InfoBoxDetailsPage;
