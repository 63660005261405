import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current animate-spin", className)}
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12a1.5 1.5 0 00-3 0A7.5 7.5 0 1112 4.5a1.5 1.5 0 000-3z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default Icon;
