import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const PauseIcon: FC<SVGIconProps> = ({ className, ...rest }) => (
  <svg
    className={classNames("fill-current", className)}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...rest}
  >
    <g id="Union">
      <g id="ic_pause">
        <path d="M7.5 5C7.22386 5 7 5.22386 7 5.5V18.5C7 18.7761 7.22386 19 7.5 19H9.5C9.77614 19 10 18.7761 10 18.5V5.5C10 5.22386 9.77614 5 9.5 5H7.5Z" />
        <path d="M14.5 5C14.2239 5 14 5.22386 14 5.5V18.5C14 18.7761 14.2239 19 14.5 19H16.5C16.7761 19 17 18.7761 17 18.5V5.5C17 5.22386 16.7761 5 16.5 5H14.5Z" />
      </g>
    </g>
  </svg>
);

export default PauseIcon;
